import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import Game from "./components/Game";
import DailyChallenge from "./components/DailyChallenge";
import HomePage from "./components/HomePage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Footer from "./components/Footer";
import "./App.css";

const socket = io("http://localhost:3001");

function App() {
  const [gameId, setGameId] = useState(null);
  const [playerId, setPlayerId] = useState(null);
  const [playerNumber, setPlayerNumber] = useState(null);
  const [gameState, setGameState] = useState("menu");
  const [error, setError] = useState(null);
  const [isDailyChallenge, setIsDailyChallenge] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("Connected to server");
      setPlayerId(socket.id);
    });

    socket.on("gameCreated", (state) => {
      console.log("Game created:", state);
      setGameId(state.gameId);
      setPlayerNumber(state.playerNumber);
      setGameState("waiting");
      setIsDailyChallenge(false);
    });

    socket.on("gameJoined", (state) => {
      console.log("Game joined:", state);
      setGameId(state.gameId);
      setPlayerNumber(state.playerNumber);
      setGameState("playing");
      setIsDailyChallenge(false);
    });

    socket.on("error", (message) => {
      console.error("Error:", message);
      setError(message);
    });

    return () => {
      socket.off("connect");
      socket.off("gameCreated");
      socket.off("gameJoined");
      socket.off("error");
    };
  }, []);

  const handleBackToHome = () => {
    setGameState("menu");
    setIsDailyChallenge(false);
  };

  const togglePrivacyPolicy = () => {
    setShowPrivacyPolicy(!showPrivacyPolicy);
  };

  const createGame = () => {
    console.log("Requesting to create a game");
    socket.emit("createGame");
  };

  const joinGame = (id) => {
    console.log("Requesting to join game:", id);
    socket.emit("joinGame", id);
  };

  const startDailyChallenge = () => {
    console.log("Starting daily challenge with test date:");
    setIsDailyChallenge(true);
    setGameState("playing");
  };

  if (isDailyChallenge) {
    return <DailyChallenge socket={socket} onBackToHome={handleBackToHome} />;
  }

  if (showPrivacyPolicy) {
    return (
      <div>
        <PrivacyPolicy onClose={togglePrivacyPolicy} />
        <Footer showPrivacyPolicy={togglePrivacyPolicy} />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <div className="error-message">Error: {error}</div>
        <Footer showPrivacyPolicy={togglePrivacyPolicy} />
      </div>
    );
  }

  if (gameState === "menu") {
    return (
      <div>
        <HomePage
          createGame={createGame}
          joinGame={joinGame}
          startDailyChallenge={startDailyChallenge}
        />
        <Footer showPrivacyPolicy={togglePrivacyPolicy} />
      </div>
    );
  }

  if (isDailyChallenge) {
    return (
      <div>
        <DailyChallenge socket={socket} />
        <Footer showPrivacyPolicy={togglePrivacyPolicy} />
      </div>
    );
  }

  return (
    <div className="App">
      <div className="content">
        {showPrivacyPolicy ? (
          <PrivacyPolicy onClose={togglePrivacyPolicy} />
        ) : gameState === "menu" ? (
          <HomePage
            createGame={createGame}
            joinGame={joinGame}
            startDailyChallenge={startDailyChallenge}
          />
        ) : isDailyChallenge ? (
          <DailyChallenge socket={socket} />
        ) : (
          <Game
            socket={socket}
            gameId={gameId}
            playerId={playerId}
            playerNumber={playerNumber}
            gameState={gameState}
            setGameState={setGameState}
          />
        )}
      </div>
      <Footer showPrivacyPolicy={togglePrivacyPolicy} />
    </div>
  );
}

export default App;
