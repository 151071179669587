import React from "react";
import "./PrivacyPolicy.css";

function PrivacyPolicy({ onClose }) {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p className="privacy-policy-date">Last updated: 22nd August, 2024</p>

      <h2>1. Information We Collect</h2>
      <p>
        We collect information you provide directly to us when using the
        ChainLink Battle game.
      </p>

      <h2>2. How We Use Your Information</h2>
      <p>
        We use the information we collect to operate and improve our game, and
        to provide you with a better gaming experience.
      </p>

      <h2>3. Sharing of Information</h2>
      <p>
        We do not share your personal information with third parties except as
        described in this privacy policy.
      </p>

      <h2>4. Security</h2>
      <p>
        We take reasonable measures to help protect your personal information
        from loss, theft, misuse, unauthorized access, disclosure, alteration,
        and destruction.
      </p>

      <h2>5. Changes to This Privacy Policy</h2>
      <p>
        We may update this privacy policy from time to time. We will notify you
        of any changes by posting the new privacy policy on this page.
      </p>

      <h2>6. Contact Us</h2>
      <p>
        If you have any questions about this privacy policy, please contact us
        at: hello@rubek.ai
      </p>

      <a
        href="#"
        className="privacy-policy-back-link"
        onClick={(e) => {
          e.preventDefault();
          onClose();
        }}
      >
        Back to Game
      </a>
    </div>
  );
}

export default PrivacyPolicy;
