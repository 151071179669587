import React, { useState } from "react";
import "./ScorePopup.css";

function ScorePopup({
  totalIncorrectGuesses,
  wordIncorrectGuesses,
  onClose,
  isCompleted,
}) {
  const [copied, setCopied] = useState(false);

  const getEmojiRepresentation = (incorrectGuesses) => {
    if (incorrectGuesses === 0) return "✅";
    return "❌".repeat(incorrectGuesses);
  };

  const scoreBreakdown = wordIncorrectGuesses
    .map(
      (guesses, index) =>
        `Word ${index + 1}: ${getEmojiRepresentation(guesses)}`
    )
    .join("\n");

  const scoreText = `ChainLink Daily Challenge\n\nTotal incorrect guesses: ${totalIncorrectGuesses}\n\n${scoreBreakdown}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(scoreText).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="score-popup">
      <h2>
        {isCompleted
          ? "You've completed today's Chain Link!"
          : "You've completed today's Chain Link!"}
      </h2>
      <p>Total incorrect guesses: {totalIncorrectGuesses}</p>
      <h3>Breakdown:</h3>
      <div className="score-breakdown">
        {wordIncorrectGuesses.map((guesses, index) => (
          <div key={index} className="word-score">
            <span>Word {index + 1}:</span>
            <span>{getEmojiRepresentation(guesses)}</span>
          </div>
        ))}
      </div>
      <button onClick={copyToClipboard}>
        {copied ? "Copied!" : "Copy Score"}
      </button>
      <button onClick={onClose}>Close</button>
    </div>
  );
}

export default ScorePopup;
