import React, { useState, useEffect } from "react";
import "./Game.css";
import ScorePopup from "./ScorePopup";

function DailyChallenge({ socket, onBackToHome }) {
  const [words, setWords] = useState([]);
  const [fullWords, setFullWords] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(1);
  const [guess, setGuess] = useState("");
  const [message, setMessage] = useState("");
  const [gameState, setGameState] = useState("loading");
  const [challengeDate, setChallengeDate] = useState(null);
  const [incorrectGuesses, setIncorrectGuesses] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [wordIncorrectGuesses, setWordIncorrectGuesses] = useState([]);
  const [totalIncorrectGuesses, setTotalIncorrectGuesses] = useState(0);

  const isValidWord = (word) => {
    return /^[a-zA-Z]+$/.test(word);
  };

  useEffect(() => {
    const storedChallenge = localStorage.getItem("dailyChallenge");
    const storedDate = localStorage.getItem("dailyChallengeDate");
    const today = new Date().toISOString().split("T")[0];

    if (storedChallenge && storedDate === today) {
      const challenge = JSON.parse(storedChallenge);
      // Use the stored challenge
      setFullWords(challenge.words.map((word) => word.toLowerCase()));
      setWords(
        challenge.words.map((word, index) =>
          index === 0
            ? word.toLowerCase()
            : word[0].toLowerCase() + "_".repeat(word.length - 1)
        )
      );
      setChallengeDate(challenge.date);
      setGameState("playing");
    } else {
      console.log("Requesting daily challenge");
      socket.emit("getDailyChallenge");
    }

    socket.on("dailyChallenge", (challenge) => {
      console.log("Daily challenge received:", challenge);
      // Store the challenge in localStorage
      localStorage.setItem("dailyChallenge", JSON.stringify(challenge));
      localStorage.setItem("dailyChallengeDate", today);

      // Use the challenge
      setFullWords(challenge.words.map((word) => word.toLowerCase()));
      setWords(
        challenge.words.map((word, index) =>
          index === 0
            ? word.toLowerCase()
            : word[0].toLowerCase() + "_".repeat(word.length - 1)
        )
      );
      setChallengeDate(challenge.date);
      setGameState("playing");
    });

    socket.on("error", (errorMessage) => {
      console.error("Error received:", errorMessage);
      setMessage(errorMessage);
      setGameState("error");
    });

    return () => {
      socket.off("dailyChallenge");
      socket.off("error");
    };
  }, [socket]);

  const handleGuessSubmit = () => {
    const cleanedGuess = guess.trim().toLowerCase();
    if (isValidWord(cleanedGuess) && currentWordIndex < words.length) {
      const currentWord = words[currentWordIndex];
      const fullWord = fullWords[currentWordIndex];

      if (cleanedGuess === fullWord || currentWord === fullWord) {
        moveToNextWord(fullWord);
      } else {
        setIncorrectGuesses((prev) => prev + 1);
        revealNextLetter(currentWord, fullWord);
      }
      setGuess("");
    } else {
      setMessage("Please enter a valid single word (letters only, no spaces).");
    }
  };

  const moveToNextWord = (fullWord) => {
    setMessage(
      `Correct! You've guessed word ${currentWordIndex + 1}: ${fullWord}`
    );
    setWords((prevWords) =>
      prevWords.map((word, index) =>
        index === currentWordIndex ? fullWord : word
      )
    );
    setWordIncorrectGuesses((prev) => [...prev, incorrectGuesses]);
    setIncorrectGuesses(0);
    setCurrentWordIndex((prevIndex) => prevIndex + 1);

    if (currentWordIndex + 1 === words.length) {
      setGameState("finished");
      setMessage(`Congratulations! You've completed the daily challenge!`);
      setShowPopup(true);
    }
  };

  const revealNextLetter = (currentWord, fullWord) => {
    let revealedWord = currentWord;
    for (let i = 0; i < fullWord.length; i++) {
      if (revealedWord[i] === "_") {
        revealedWord =
          revealedWord.slice(0, i) + fullWord[i] + revealedWord.slice(i + 1);
        break;
      }
    }

    setWords((prevWords) =>
      prevWords.map((word, index) =>
        index === currentWordIndex ? revealedWord : word
      )
    );
    setMessage("Incorrect guess. Try again!");

    // Check if all letters are revealed
    if (revealedWord === fullWord) {
      moveToNextWord(fullWord);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleGuessSubmit();
    }
  };

  const renderGame = () => (
    <div className="game-container">
      <h2 className="game-title">Daily Chain Challenge</h2>
      <div className="game-header">
        <button onClick={onBackToHome} className="back-button outlined-button">
          Back to Home
        </button>
      </div>

      {challengeDate && (
        <p className="challenge-date">
          Challenge for: {new Date(challengeDate).toLocaleDateString()}
        </p>
      )}
      <div className="words-container">
        {words.map((word, index) => (
          <div
            key={index}
            className={`word-container ${
              index === currentWordIndex ? "current-word" : ""
            }`}
          >
            <div className="word">{word}</div>
          </div>
        ))}
      </div>
      <input
        type="text"
        value={guess}
        onChange={(e) => setGuess(e.target.value.replace(/[^a-zA-Z]/g, ""))}
        onKeyPress={handleKeyPress}
        placeholder="Enter your guess"
        disabled={gameState !== "playing" || currentWordIndex >= words.length}
        maxLength={20}
      />
      <button
        onClick={handleGuessSubmit}
        disabled={gameState !== "playing" || currentWordIndex >= words.length}
      >
        Submit Guess
      </button>
      <p className="game-message">{message}</p>
      <p>Total incorrect guesses: {totalIncorrectGuesses}</p>

      {showPopup && (
        <ScorePopup
          totalIncorrectGuesses={totalIncorrectGuesses}
          wordIncorrectGuesses={wordIncorrectGuesses}
          onClose={() => setShowPopup(false)}
          isCompleted={true}
        />
      )}
    </div>
  );

  const renderEndScreen = () => (
    <div className="game-container">
      <h2 className="game-title">Daily Challenge Completed!</h2>
      <p className="game-message">{message}</p>
      <p>Total incorrect guesses: {totalIncorrectGuesses}</p>
      <div className="words-container">
        {fullWords.map((word, index) => (
          <div key={index} className="word-container">
            <div className="word">{word}</div>
          </div>
        ))}
      </div>
      <p>Come back tomorrow for a new challenge!</p>

      {showPopup && (
        <ScorePopup
          totalIncorrectGuesses={totalIncorrectGuesses}
          wordIncorrectGuesses={wordIncorrectGuesses}
          onClose={() => setShowPopup(false)}
          isCompleted={true}
        />
      )}
    </div>
  );

  if (gameState === "loading") {
    return <div className="game-container">Loading daily challenge...</div>;
  }

  if (gameState === "error") {
    return <div className="game-container">Error: {message}</div>;
  }

  if (gameState === "finished") {
    return renderEndScreen();
  }

  return renderGame();
}

export default DailyChallenge;
