import React from "react";
import "./Footer.css";

function Footer({ showPrivacyPolicy }) {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; 2024 ChainLink Battle. All rights reserved.</p>
        <a
          href="#"
          className="privacy-link"
          onClick={(e) => {
            e.preventDefault();
            showPrivacyPolicy();
          }}
        >
          Privacy Policy
        </a>
      </div>
    </footer>
  );
}

export default Footer;
