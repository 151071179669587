import React, { useState } from "react";
import "./HomePage.css";

function HomePage({ createGame, joinGame, startDailyChallenge }) {
  const [joinGameId, setJoinGameId] = useState("");

  const handleJoinGame = (e) => {
    e.preventDefault();
    if (joinGameId.trim()) {
      joinGame(joinGameId.toUpperCase());
    }
  };

  return (
    <div className="home-container">
      <h1 className="game-title">ChainLink Battle</h1>
      <p className="game-subtitle">
        Guess a word chain of 7 words, play with friends or try the daily
        challenge below!
      </p>

      <div className="game-modes">
        <div className="game-mode">
          <h2>Single Player</h2>
          <button onClick={startDailyChallenge} className="button primary">
            Play Daily Challenge
          </button>
        </div>

        <div className="game-mode">
          <h2>Multiplayer</h2>
          <button onClick={createGame} className="button secondary">
            Create New Game
          </button>
          <div className="divider">
            <span>OR</span>
          </div>
          <form onSubmit={handleJoinGame} className="join-game-form">
            <input
              type="text"
              value={joinGameId}
              onChange={(e) => setJoinGameId(e.target.value.toUpperCase())}
              placeholder="Enter Game Code"
              className="input"
            />
            <button type="submit" className="button secondary">
              Join Game
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
