import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaCopy } from "react-icons/fa";
import "./Game.css";

function Game({
  socket,
  gameId,
  playerId,
  playerNumber,
  gameState,
  setGameState,
}) {
  const [words, setWords] = useState(Array(7).fill(""));
  const [fullWords, setFullWords] = useState([]); // eslint-disable-line no-unused-vars
  const [opponentWords, setOpponentWords] = useState(Array(7).fill("_"));
  const [currentWordIndex, setCurrentWordIndex] = useState(1);
  const [guess, setGuess] = useState("");
  const [message, setMessage] = useState("");
  const [wordsSubmitted, setWordsSubmitted] = useState(false);
  const [incorrectGuesses, setIncorrectGuesses] = useState(0);
  const [opponentIncorrectGuesses, setOpponentIncorrectGuesses] = useState(0);
  const [finishedGuessing, setFinishedGuessing] = useState(false);
  const [opponentFinishedGuessing, setOpponentFinishedGuessing] =
    useState(false);
  const [copied, setCopied] = useState(false);
  const [playAgainReady, setPlayAgainReady] = useState(false);
  const [opponentPlayAgainReady, setOpponentPlayAgainReady] = useState(false);

  const isValidWord = (word) => {
    return /^[a-zA-Z]+$/.test(word);
  };

  useEffect(() => {
    socket.on("gameStateUpdate", (state) => {
      console.log("Game state update received:", state);
      setGameState(state.gameState);
      setOpponentWords(state.opponentWords || Array(7).fill("_"));
      setCurrentWordIndex(state.currentWordIndex || 1);
      setIncorrectGuesses(state.incorrectGuesses || 0);
      setOpponentIncorrectGuesses(state.opponentIncorrectGuesses || 0);
      setWordsSubmitted(state.wordsSubmitted || false);
      setFinishedGuessing(state.finishedGuessing || false);
      setOpponentFinishedGuessing(state.opponentFinishedGuessing || false);

      if (state.gameState === "waiting") {
        setMessage("Waiting for opponent to join...");
      } else if (state.gameState === "playing") {
        setMessage("Enter your 7 linked words!");
      } else if (state.gameState === "guessing") {
        setMessage(
          state.finishedGuessing
            ? "Waiting for opponent to finish guessing..."
            : "Start guessing your opponent's words!"
        );
      }
    });

    socket.on(
      "correctGuess",
      ({ playerId: guessingPlayerId, wordIndex, word }) => {
        if (guessingPlayerId === playerId) {
          setMessage(`Correct! You've guessed word ${wordIndex + 1}: ${word}`);
          setCurrentWordIndex(wordIndex + 1);
          if (wordIndex + 1 === 7) {
            setFinishedGuessing(true);
            setMessage(
              "You've guessed all words! Waiting for opponent to finish..."
            );
          }
        } else {
          setMessage(`Opponent guessed word ${wordIndex + 1} correctly.`);
        }
      }
    );

    socket.on(
      "incorrectGuess",
      ({ playerId: guessingPlayerId, incorrectGuesses, revealedWord }) => {
        if (guessingPlayerId === playerId) {
          setMessage("Incorrect guess. Try again!");
          setIncorrectGuesses(incorrectGuesses);
          const newOpponentWords = [...opponentWords];
          newOpponentWords[currentWordIndex] = revealedWord;
          setOpponentWords(newOpponentWords);
        } else {
          setOpponentIncorrectGuesses(incorrectGuesses);
        }
      }
    );

    socket.on("gameOver", ({ winner }) => {
      setGameState("finished");
      setMessage(
        winner === playerId
          ? "Congratulations! You won!"
          : winner === "tie"
          ? "It's a tie!"
          : "Game over. You lost."
      );
    });

    socket.on("playAgainUpdate", ({ playerId: readyPlayerId, ready }) => {
      if (readyPlayerId === playerId) {
        setPlayAgainReady(ready);
      } else {
        setOpponentPlayAgainReady(ready);
      }
    });

    socket.on("playAgainConfirmed", () => {
      console.log("Play again confirmed, resetting game state");
      setGameState("playing");
      setWords(Array(7).fill(""));
      setFullWords(Array(7).fill(""));
      setOpponentWords(Array(7).fill("_"));
      setCurrentWordIndex(1);
      setGuess("");
      setMessage("Enter your 7 linked words!");
      setWordsSubmitted(false);
      setIncorrectGuesses(0);
      setOpponentIncorrectGuesses(0);
      setFinishedGuessing(false);
      setOpponentFinishedGuessing(false);
      setPlayAgainReady(false);
      setOpponentPlayAgainReady(false);
    });

    return () => {
      socket.off("gameStateUpdate");
      socket.off("correctGuess");
      socket.off("incorrectGuess");
      socket.off("gameOver");
      socket.off("playAgainUpdate");
      socket.off("playAgainConfirmed");
    };
  }, [socket, playerId, opponentWords, currentWordIndex, setGameState]);

  const handleWordSubmit = () => {
    if (!wordsSubmitted && words.every((word) => isValidWord(word))) {
      console.log("Submitting words:", words);
      const lowercaseWords = words.map((word) => word.toLowerCase());
      socket.emit("submitWords", { gameId, words: lowercaseWords });
      setWordsSubmitted(true);
      setFullWords(lowercaseWords);
      setMessage("Waiting for opponent to submit their words...");
    } else {
      setMessage(
        "Please fill in all words with valid single words (letters only, no spaces)."
      );
    }
  };

  const handleGuessSubmit = () => {
    const cleanedGuess = guess.trim().toLowerCase();
    if (isValidWord(cleanedGuess) && currentWordIndex < opponentWords.length) {
      console.log("Submitting guess:", cleanedGuess);
      socket.emit("makeGuess", { gameId, guess: cleanedGuess });
      setGuess("");
    } else {
      setMessage("Please enter a valid single word (letters only, no spaces).");
    }
  };

  const handlePlayAgain = () => {
    console.log("Requesting to play again");
    socket.emit("playAgain", { gameId });
    setPlayAgainReady(true);
  };

  const handleLeaveGame = () => {
    console.log("Leaving game");
    socket.emit("leaveGame", { gameId });
    setGameState("menu");
  };

  const renderWaiting = () => (
    <div className="game-container waiting-screen">
      <h2 className="game-title">Waiting for Opponent</h2>
      <p className="player-info">You are Player {playerNumber}</p>
      <div className="game-code-container">
        <p className="game-code-label">Game Code:</p>
        <p className="game-code">{gameId}</p>
        <CopyToClipboard text={gameId} onCopy={() => setCopied(true)}>
          <button className="copy-button">
            Copy <FaCopy />
          </button>
        </CopyToClipboard>
      </div>
      {copied && <p className="copied-message">Copied to clipboard!</p>}
      <p className="game-message">{message}</p>
    </div>
  );

  const renderWordInputs = () => (
    <div className="game-container">
      <h2 className="game-title">Enter Your Words</h2>
      <p className="player-info">You are Player {playerNumber}</p>
      <div className="word-input-container">
        {words.map((word, index) => (
          <input
            key={index}
            type="text"
            value={word}
            onChange={(e) => {
              const newWords = [...words];
              newWords[index] = e.target.value.replace(/[^a-zA-Z]/g, "");
              setWords(newWords);
            }}
            placeholder={`Word ${index + 1}`}
            disabled={wordsSubmitted}
            maxLength={20}
          />
        ))}
      </div>
      {!wordsSubmitted && (
        <button onClick={handleWordSubmit}>Submit Words</button>
      )}
      <p className="game-message">{message}</p>
    </div>
  );

  const renderGuessingPhase = () => (
    <div className="game-container">
      <h2 className="game-title">Guessing Phase</h2>
      <p className="player-info">You are Player {playerNumber}</p>
      <div className="opponent-words-container">
        {opponentWords.map((word, index) => (
          <div
            key={index}
            className={`word-container ${
              index === currentWordIndex ? "current-word" : ""
            }`}
          >
            <div className="opponent-word">{word}</div>
          </div>
        ))}
      </div>
      {!finishedGuessing && (
        <>
          <input
            type="text"
            value={guess}
            onChange={(e) => setGuess(e.target.value.replace(/[^a-zA-Z]/g, ""))}
            placeholder="Enter your guess"
            maxLength={20}
          />
          <button onClick={handleGuessSubmit}>Submit Guess</button>
        </>
      )}
      <p>Your incorrect guesses: {incorrectGuesses}</p>
      <p>Opponent's incorrect guesses: {opponentIncorrectGuesses}</p>
      <p className="game-message">{message}</p>
      {finishedGuessing && <p>You've finished guessing all words!</p>}
      {opponentFinishedGuessing && (
        <p>Your opponent has finished guessing all words!</p>
      )}
    </div>
  );

  const renderEndScreen = () => (
    <div className="game-container">
      <h2 className="game-title">Game Over</h2>
      <p className="game-message">{message}</p>
      <p>Your incorrect guesses: {incorrectGuesses}</p>
      <p>Opponent's incorrect guesses: {opponentIncorrectGuesses}</p>
      <button onClick={handlePlayAgain} disabled={playAgainReady}>
        {playAgainReady ? "Waiting for opponent..." : "Play Again"}
      </button>
      {opponentPlayAgainReady && <p>Your opponent is ready to play again!</p>}
      <button onClick={handleLeaveGame}>Leave Game</button>
    </div>
  );

  if (gameState === "waiting") {
    return renderWaiting();
  } else if (gameState === "playing") {
    return renderWordInputs();
  } else if (gameState === "guessing") {
    return renderGuessingPhase();
  } else if (gameState === "finished") {
    return renderEndScreen();
  } else {
    return <div>Waiting for game to start...</div>;
  }
}

export default Game;
